import React from 'react';
import './front.css'; // Importa el CSS que acabas de crear o ajustar
import Historia from './Historia/Historia'; //
import Footer from '../Footer/Footer'; //
import Testimonios from './Testimonios/Testimonios'; //
import Servicios from './Servicios/Servicios'; //

function Front() {
    return (

        <div>
 <div className="container">
            <h2>CONOCE NUESTRA HISTORIA</h2></div>
        <Historia />
        <Servicios />
        <Testimonios />
        <Footer />

        </div>
    );
}

export default Front;