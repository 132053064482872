import React from 'react';
import './Footer.css'; // Asegúrate de crear este archivo CSS

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div>
                    <h5>Academia de Historia Militar de Bolivia</h5>
                    <ul>
                        <li><a href="#privacy">Política de privacidad</a></li>
                        <li><a href="#terms">Términos de servicio</a></li>
                    </ul>
                </div>
                <div>
                    <h5>Contacto</h5>
                    <p>info@academiamilitarhistoria.com</p>
                    <p>+591 70776212</p>
                </div>
                <div>
                    <h5>Síguenos</h5>
                    <a href="https://twitter.com/username"><i className="fab fa-twitter"></i></a>
                    <a href="https://facebook.com/username"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://instagram.com/username"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Academia de Historia Militar de Bolivia. Todos los derechos reservados.</p>
                <p>&copy; CNL.DAEN.Raul Alarcon Gonzales</p>
            </div>
        </footer>
    );
}

export default Footer;
