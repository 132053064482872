import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './Components/NavBar/NavBar'; // 
import Front from './Components/Principal/front'; // 
import Historia from './Components/Principal/Historia/Historia'; // 

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <Router>
          <NavBar />
          <Routes>
              <Route path="/" element={<Front />} />
              <Route path="/historia" element={<Historia />} />
              {/* Puedes agregar más rutas aquí */}
          </Routes>
      </Router>
  );
}

export default App;