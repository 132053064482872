import React, { useState } from 'react';
import './Historia.css'; // Asegúrate de crear este archivo CSS


function Historia() {
    const historias = [
        {
            id: 1,
            title: "La Batalla de Boqueron",
            description: "Un momento decisivo en la historia militar.",
            image: "https://www.lostiempos.com/sites/default/files/styles/noticia_detalle/public/media_imagen/2018/10/7/la_nacion.jpg?itok=Neko21BM"
        },
        {
            id: 2,
            title: "Cnl Marzana",
            description: "La lucha por Boqueron.",
            image: "https://erbol.com.bo/sites/default/files/manuelmarzana-179-21.jpg"
        },
        {
            id: 3,
            title: "Juancito Pinto",
            description: "La Historia",
            image: "https://www.ibolivia.org/wp-content/uploads/2019/10/juancito-pinto-bolivia.jpg"
        },
        {
            id: 4,
            title: "Guerra del Pacifico",
            description: "Conoce la Verdad",
            image: "https://historiauniversal.org/wp-content/uploads/guerra-del-pacifico.jpg"
        },
        {
            id: 5,
            title: "La Batalla de Aroma",
            description: "La Batalla de Aroma",
            image: "https://eju.tv/wp-content/uploads/2021/11/2-3.jpg"
        }




        // Agrega más historias aquí
    ];

    const [index, setIndex] = useState(0); // Estado para controlar el índice visible

    // Función para avanzar a la próxima terna de tarjetas
    const next = () => {
        setIndex((prevIndex) =>
            prevIndex + 3 < historias.length ? prevIndex + 3 : prevIndex
        );
    };

    // Función para retroceder a la terna anterior de tarjetas
    const prev = () => {
        setIndex((prevIndex) =>
            prevIndex - 3 >= 0 ? prevIndex - 3 : 0
        );
    };

    return (
        <div className="historia-container">
            <button onClick={prev} disabled={index === 0}>&laquo;</button>
            <div className="cards-container">
                {historias.slice(index, index + 3).map(historia => (
                    <div key={historia.id} className="card">
                        <img src={historia.image} alt={historia.title} className="card-img-top" />
                        <div className="card-body">
                            <h5 className="card-title">{historia.title}</h5>
                            <p className="card-text">{historia.description}</p>
                            <a href="#" className="btn btn-primary">Leer más</a>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={next} disabled={index + 3 >= historias.length}>&raquo;</button>
        </div>
    );
}

export default Historia;
