import React from 'react';
import './NavBar.css'; // Solo importa el CSS aquí
import Logo from '../../Imagenes/abhmok_trs.png'; // Asegúrate de que el path es correcto

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#"><img src={Logo} alt="Logo" style={{ height: '30px', marginRight: '10px' }} /> 
                AHMB</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item active">
                        <a className="nav-link" href="#home">Inicio <span className="sr-only"></span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#about">Acerca de</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#contact">Contacto</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;