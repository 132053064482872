import React from 'react';
import './Servicios.css'; // Asegúrate de incluir este archivo CSS

const servicesData = [
    {
        icon: "📚", // Icono para venta de libros digitales
        title: "Venta de Libros Digitales",
        description: "Explora nuestra vasta colección de libros digitales enfocados en la historia militar y enriquece tu conocimiento."
    },
    {
        icon: "🗺️", // Icono para guías turísticas
        title: "Guías Turísticas de Historia Militar",
        description: "Descubre los campos de batalla históricos y monumentos con nuestras guías turísticas detalladas."
    },
    {
        icon: "📖", // Icono para asesoría
        title: "Asesoría y Consulta",
        description: "Obtén asesoría profesional para proyectos educativos o investigaciones relacionadas con la historia militar."
    },
    {
        icon: "🔍", // Icono para consulta
        title: "Investigación y Desarrollo",
        description: "Apoyamos tus proyectos de investigación con recursos expertos y análisis especializado en historia militar."
    }
];

function Servicios() {
    return (
        <div className="services-container">
            <h2>Nuestros Servicios</h2>
            <div className="services-list">
                {servicesData.map((service, index) => (
                    <div key={index} className="service-item">
                        <div className="service-icon">{service.icon}</div>
                        <h3 className="service-title">{service.title}</h3>
                        <p className="service-description">{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Servicios;
