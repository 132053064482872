import React from 'react';
import Slider from 'react-slick';
import './Testimonios.css'; // Asegúrate de crear este archivo CSS

function Testimonios() {
    const heroes = [
        {
            id: 1,
            name: "Douglas MacArthur",
            image: "url_de_la_imagen_de_macarthur",
            testimony: "Nunca he escuchado a un hombre disparar y no escuchar su eco en la eternidad."
        },
        {
            id: 2,
            name: "Audie Murphy",
            image: "url_de_la_imagen_de_murphy",
            testimony: "El verdadero valor es ser un héroe en la vida diaria, no en la guerra."
        },
        {
            id: 3,
            name: "Reg. Camacho",
            image: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj9kKjOJvxDOXELOTByPxVyhyphenhyphenYpJaCiy6EyGTNGG-cdL6vZFqLW8sv1S_F8540tT5X3Q1rbxeCEUWlnh_Y9E-obSYZ2EFatM5r4S4CFej7n06XByZjJYpolskZwqrRaA3CVVDLTUq-nRUJO/s640/camacho.jpg",
            testimony: "Donde Forjan al mejor Soldado Boliviano con honor y lealtad hasta la Inmortalidad,  Mole de hierro, Corazón de Acero. Tenemos el Alma noble y el Temple de Acero.PONER EN LO MAS ALTO LA TRICOLOR, Ahi comienza la Victoria."
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <div className="slick-slider">
            <Slider {...settings}>
                {heroes.map(heroe => (
                    <div key={heroe.id} className="testimonial-card">
                        <div className="testimonial-image">
                            <img src={heroe.image} alt={heroe.name} />
                        </div>
                        <div className="testimonial-text">
                            <h5>{heroe.name}</h5>
                            <p>{heroe.testimony}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block', background: 'red' }} onClick={onClick} />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block', background: 'red' }} onClick={onClick} />
    );
}

export default Testimonios;
